<div class="table-outer-container">
  <div
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="10"
    [scrollWindow]="false"
    class="infiniteScrollDiv"
  >
    <mat-table
      (matSortChange)="sortData($event)"
      [dataSource]="documents$ | async"
      class="custom-table"
      matSort
    >
      <ng-container matColumnDef="expand">
        <mat-header-cell *matHeaderCellDef>
          <span class="custom-ellipsis" title="{{'document-table.history' | translate | uppercase }}">
            {{ "document-table.history" | translate | uppercase }}
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.parentId; else actual">
            <mat-icon>subdirectory_arrow_right</mat-icon>
          </ng-container>
          <ng-template #actual>
            <button
              (click)="switch(element)"
              *ngIf="element.hasVersions && !element.parentId"
              class="action-btn arrow-size"
            >
              <mat-icon class="arrowButtons">{{
                element.isOpened
                  ? "keyboard_arrow_down"
                  : "keyboard_arrow_right"
              }}</mat-icon>
            </button>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="visibleForCustomer">
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          <div class="table-header">
            <span class="custom-ellipsis" title="{{'document-table.visibility_for_customer' | translate | uppercase}}">
              {{ "document-table.visibility_for_customer" | translate | uppercase }}
            </span>
            <img
              alt=""
              class="table-header__image"
              src="../../../assets/images/ic-icons/Sort.svg"
            />
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox
            [checked]="element.visibleForCustomer"
            disabled
            class="custom-checkBox"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="documentType">
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          <div class="table-header">
            <span class="custom-ellipsis" title="{{'document-table.type' | translate | uppercase}}">
              {{ "document-table.type" | translate | uppercase }}
            </span>
            <img
              alt=""
              class="table-header__image"
              src="../../../assets/images/ic-icons/Sort.svg"
            />
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="custom-ellipsis" title="{{element.documentType}}">
            {{ element.documentType }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          <div class="table-header">
            <span class="custom-ellipsis" title="{{'document-table.description' | translate | uppercase}}">
              {{ "document-table.description" | translate | uppercase }}
            </span>
            <img
              alt=""
              class="table-header__image"
              src="../../../assets/images/ic-icons/Sort.svg"
            />
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="custom-ellipsis" title="{{element.description}}">
            {{ element.description }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          <div class="table-header">
            <span class="custom-ellipsis" title="{{'document-table.created_by' | translate | uppercase}}">
              {{ "document-table.created_by" | translate | uppercase }}
            </span>
            <img
              alt=""
              class="table-header__image"
              src="../../../assets/images/ic-icons/Sort.svg"
            />
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="custom-ellipsis" title="{{element.createdBy}}">
            {{ element.createdBy }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sourceTarget">
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          <div class="table-header">
            <span class="custom-ellipsis" title="{{'document-table.source_target' | translate | uppercase}}">
              {{ "document-table.source_target" | translate | uppercase }}
            </span>
            <img
              alt=""
              class="table-header__image"
              src="../../../assets/images/ic-icons/Sort.svg"
            />
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="custom-ellipsis" title="{{ element.sourceUser }}{{ element.targetUser ? '/' : '' }}{{ element.targetUser }}">
            {{ element.sourceUser }}{{ element.targetUser ? '/' : '' }}{{ element.targetUser }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="uploadDate">
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          <div class="table-header">
            <span class="custom-ellipsis" title="{{'document-table.uploading_date' | translate | uppercase}}">
              {{ "document-table.uploading_date" | translate | uppercase }}
            </span>
            <img
              alt=""
              class="table-header__image"
              src="../../../assets/images/ic-icons/Sort.svg"
            />
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="custom-ellipsis" title="{{element.uploadDate | dateFormat}}">
            {{ element.uploadDate | dateFormat }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>
          <span class="custom-ellipsis" title="{{'document-table.action' | translate | uppercase }}">
            {{ "document-table.action" | translate | uppercase }}
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="actions">
            <button class="actions__dots">&#183; &#183; &#183;</button>
            <div class="actions__hide">
              <button
                (click)="element.draft ? openOfficeLink(element) : getSecuredDocument(element)"
                mat-icon-button>
                <img
                  alt=""
                  matTooltip="{{ 'document-table.open' | translate }}"
                  src="../../../assets/images/new-icons/Open Doc.svg"
                />
              </button>
              <button
                *ngIf="(isICUser$ | async) && !element.parentId && !element.draft"
                mat-icon-button
                (click)="editMetaData(element)"
                [disabled]="!canEdit"
              >
                <img
                  alt=""
                  matTooltip="{{ 'document-table.edit' | translate }}"
                  src="../../../assets/images/new-icons/edit_icon.svg"
                />
              </button>
              <button
                *ngIf="(isICUser$ | async) && !element.parentId"
                mat-icon-button
                (click)="callDeleteDialog(element)"
                [disabled]="!canEdit"
              >
                <img
                  alt=""
                  matTooltip="{{ 'document-table.delete' | translate }}"
                  src="../../../assets/images/new-icons/Delete icon.svg"
                />
              </button>
              <button
                *ngIf="(isICUser$ | async) && !element.parentId && !element.draft"
                mat-icon-button
                (click)="replace(element)"
                [disabled]="!canEdit"
              >
                <img
                  alt=""
                  matTooltip="{{ 'document-table.replace' | translate }}"
                  src="../../../assets/images/Replace_Template_Icon.svg"
                />
              </button>
              <button
                *ngIf="(isICUser$ | async) && !element.parentId && !element.draft"
                mat-icon-button
                (click)="redirectToLuna()"
                [disabled]="!canEdit"
              >
                <img
                  alt=""
                  matTooltip="{{ 'document-table.see_summary' | translate }}"
                  src="../../../../assets/images/Redirect_To_Luna.svg"
                />
              </button>
              <button
                *ngIf="(isICUser$ | async) && element.draft"
                mat-icon-button
                (click)="sendDraftToAzure(element)"
              >
                <img
                  width="16px"
                  alt=""
                  matTooltip="{{ 'document-table.send_to_azure' | translate }}"
                  src="../../../assets/images/new-icons/upload_csv_dropdown.svg"
                />
              </button>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row [ngClass]="{'row--opened' : row.isOpened}" *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <custom-pdf-viewer
        *ngIf="isShouldShowPdfModal$ | async"
        [pdfLink]="pdfLink$ | async"
        (onClosePdf)="closePdfModal()"
        [via]="'policyTemplate'"
        [blob]="blobStream$"
        ></custom-pdf-viewer>

  <custom-warning-popup
    *ngIf="isShouldShowWarning$ | async"
    (onHideModal)="closeWarning()"
    (onDelete)="deleteDoc()"
  ></custom-warning-popup>
</div>
